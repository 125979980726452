import React, { useState } from 'react'
import { Graphs } from './Graphs'
import { Icon } from '../Forms/FormPieces/BasicFormComponents'
import { CacheTools } from './CacheTools'

type ToolGroup = { name: string; jsx: JSX.Element; openByDefault: boolean }
const TOOLS: Array<ToolGroup> = [
	{ name: 'Graphs', jsx: <Graphs />, openByDefault: true },
	{ name: 'Cache', jsx: <CacheTools />, openByDefault: false },
]

/**
 * ToolsTab - a react component which shows a list of available tools
 *
 * @returns {JSX.Element}
 */
export default function ToolsTab(): JSX.Element {
	return (
		<div className="p-4 flex flex-col gap-2">
			{TOOLS.map((toolGroup, index) => (
				<SubTab toolGroup={toolGroup} key={index} />
			))}
		</div>
	)
}

/**
 * SubTab - a collapsible component used to render a toolGroup
 *
 * @param {Object} props - the react props
 * @param {ToolGroup} props.toolGroup - the toolGroup to render
 *
 * @return {JSX.Element}
 */
function SubTab({ toolGroup }: { toolGroup: ToolGroup }): JSX.Element {
	const [isExpanded, setIsExpanded] = useState(toolGroup.openByDefault)

	return (
		<div>
			<h1 className="text-xl font-bold cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
				{toolGroup.name} <Icon name={isExpanded ? 'caret-down' : 'caret-right'}></Icon>
			</h1>
			{isExpanded ? toolGroup.jsx : null}
		</div>
	)
}
