import React, { ReactNode } from 'react'
import { Switch } from '../../../../../common/Switch'
import { useSelector } from 'react-redux'
import type { LiteracyEventAction } from '@mission.io/mission-toolkit/actions'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { selectors } from '../../../../../reducers/simulationEditor'

type LiteracyEventAssignment = LiteracyEventAction<string>['assignment']

/**
 * A component for editing the assignment of a literacy event.
 * There are currently 2 types of assignments:
 * 1. A general assignment, implying that a single reading context will be given to every student.
 * 2. A per-team assignment, implying that a specific reading context will be assigned to each team.
 * @param {*} param0
 * @returns
 */
export function Assignment({
	value: assignment,
	onChange,
	rootAction: literacyEventAction,
}: {
	value: LiteracyEventAssignment
	onChange: (assignment: LiteracyEventAssignment) => void
	rootAction: LiteracyEventAction<string>
}): ReactNode {
	const teamSpecificAssignment = assignment.type === LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM
	const isJunior = useSelector(selectors.isJunior)

	return (
		<div>
			<Switch
				on={teamSpecificAssignment}
				label={
					teamSpecificAssignment ? (
						<span>
							Per Team <i>(Switch to assign the first reading context to the whole class)</i>
						</span>
					) : (
						<span>
							General{' '}
							{!isJunior && <i>(Switch to assign different reading contexts to different teams)</i>}
						</span>
					)
				}
				onChange={() => {
					if (teamSpecificAssignment) {
						onChange({ type: LITERACY_EVENT.ASSIGNMENT.TYPE.GENERAL })
					} else {
						onChange({
							type: LITERACY_EVENT.ASSIGNMENT.TYPE.PER_TEAM,
							teams: [],
						})
					}
				}}
				disabled={isJunior}
			/>
		</div>
	)
}
