import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, Container, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { sortBy } from 'lodash'
import NewSimulationModal from './NewSimulationModal'
import AutomatedSimulationList from './AutomatedSimulationList'
import { Card, CardBody, CardFooter, CardHeader } from './SimulationCard'
import { actions, selectors } from '../../setup/tiles'
import { Spinner } from '../../common/Spinner'
import { values } from '../../helpers/functions'
import type { Simulation as SimulationType } from '../../types'
import { UploadAllMissionGuidesButton } from '../automatedSimulations/UploadAllMissionGuidesButton'
import { useAutomatedSimulations } from '../automatedSimulations/queries'
import { ReduxStore } from '../../types/ReduxStore'
import { ClearAssetDurationCache } from '../automatedSimulations/Forms/ClearCache'
import { BasicAutomatedSimulation } from '../../types/AutomatedSimulation'
import { Collapse } from '../../common/Collapse'
export default function SimulationList(): JSX.Element {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [simulationFilter, setSimulationFilter] = useState('')
	const { data: simulations, isLoading } = useAutomatedSimulations()

	const toggleModal = () => {
		setModalIsOpen((state) => !state)
	}

	/**
	 * A filter function that returns whether a simulation should be shown in the list
	 */
	const filter = useCallback(
		(simulation: { name: string }) =>
			simulation.name.toLowerCase().includes(simulationFilter.toLowerCase()),
		[simulationFilter]
	)
	const inputRef = useRef<HTMLInputElement>()
	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [])
	return (
		<Container>
			<Row>
				<Col>
					<Input
						// @ts-expect-error the types for innerRef in reactstrap do not allow this perfectly valid ref
						innerRef={inputRef}
						placeholder="Search Simulations"
						value={simulationFilter}
						onChange={(e) => setSimulationFilter(e.currentTarget.value)}
					/>
				</Col>
				<Col css="display: flex; justify-content: flex-end;">
					<MoreTools simulations={simulations} isLoadingSimulations={isLoading} />
					<Button onClick={toggleModal} className="shadow" css="margin-left: 8px;">
						New Simulation
					</Button>
				</Col>
			</Row>
			<AutomatedSimulationList filter={filter} />
			<OldSimulationsList filter={filter} />

			<NewSimulationModal isOpen={modalIsOpen} onClose={toggleModal} />
		</Container>
	)
}
/**
 * Displays a list of all old (non automated) simulations. If the list has not
 * yet been fetched, dispatches an action to fetch it.
 */

function OldSimulationsList({ filter }: { filter: (simulation: SimulationType) => boolean }) {
	let oldSimulations: Array<SimulationType> = useSelector((state: ReduxStore) =>
		values(selectors.simulations.store(state))
	)
	oldSimulations = sortBy(oldSimulations, 'name')
	const { fetched: hasLoaded, isPending: isLoading } = useSelector(selectors.simulations.getAll)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!hasLoaded) {
			dispatch(actions.simulations.getAll())
		}
	}, [hasLoaded, dispatch])
	return (
		<>
			<h2 className="text-2xl font-bold my-2">Old Simulations</h2>
			{isLoading && oldSimulations.length === 0 ? (
				<Spinner />
			) : (
				oldSimulations.filter(filter).map((simulation) => (
					<Card key={simulation._id}>
						<CardHeader text={simulation.name} />
						<CardBody
							data={[
								['Questions', simulation.questions.length],
								['Cards', simulation.deck.cards.length],
								['Created on', new Date(simulation.createdAt).toLocaleDateString()],
							]}
						/>
						<CardFooter id={simulation._id} />
					</Card>
				))
			)}
		</>
	)
}

/**
 * MoreTools - a button which will show a modal with tools that can affect multiple simulations at once.
 *
 * @param {Object} props - the react props
 * @param {boolean} props.isLoadingSimulations - true if the simulations are loading
 * @param {null | Array<BasicAutomatedSimulation>} props.simulations - a list of every automated simulation
 *
 * @return {JSX.Element}
 */
function MoreTools({
	isLoadingSimulations,
	simulations,
}: {
	isLoadingSimulations: boolean
	simulations?: null | Array<BasicAutomatedSimulation>
}): JSX.Element {
	const [isOpen, setIsOpen] = useState(false)
	const [showMore, setShowMore] = useState(false)

	return (
		<div>
			<Button onClick={() => setIsOpen(true)} color="link">
				More Tools
			</Button>
			<Modal isOpen={isOpen} style={{ minWidth: '50%' }}>
				<ModalHeader toggle={() => setIsOpen(false)}>More Tools</ModalHeader>
				<ModalBody>
					<div className="mb-4">
						<div className="flex flex-row justify-between">
							<div>
								<h1 className="font-bold mb-2">Upload Mission Guides For Multiple Simulations</h1>
								<div className="text-sm">
									Allows for uploading mission guides for many simulations at once.
								</div>
							</div>
							{!isLoadingSimulations && !!simulations && (
								<UploadAllMissionGuidesButton simulations={simulations} />
							)}
						</div>
					</div>
					<Collapse isOpen={showMore}>
						<div className="flex flex-row justify-between items-center gap-4 my-2">
							<div>
								<h1 className="font-bold mb-2">Media Duration Cache</h1>
								<div className="text-sm">
									Remove the cached durations for all media in all simulations. Press this button if
									assets have been updated directly in the AWS S3 bucket without being renamed.
									Pressing this button will cause long load times for users the first time each
									simulation is loaded.
								</div>
							</div>
							<ClearAssetDurationCache />
						</div>
					</Collapse>
					<div
						className="text-sm text-gray-b hover:text-gray-a cursor-pointer float-right"
						onClick={() => setShowMore(!showMore)}>
						{showMore ? 'Hide' : 'Show'} Advanced Tools
					</div>
				</ModalBody>
			</Modal>
		</div>
	)
}
