import React, { useMemo, useState } from 'react'
import { CondensedActionGraphRenderer } from './GraphVisualizations/CondensedActionGraph'
import {
	isCondensedNodeInDeadEnd,
	useEditorActionsWithMediaDuration,
} from './GraphVisualizations/graphHelpers'
import { useEditorSimulation } from '../../../../reducers/simulationEditor'
import { ActionGraphRenderer } from './GraphVisualizations/ActionGraph'
import {
	ActionGraph,
	getCondensedActionGraph,
	type CondensedActionGraph,
} from '@mission.io/mission-toolkit'
import { Button } from 'reactstrap'
import { styled } from 'styled-components'
import { Spinner } from '../../../../common/Spinner'
import type { Action } from '@mission.io/mission-toolkit/actions'
import { SelectedActionContextProvider } from './GraphVisualizations/Context'
import { NotableEventsGraph } from './GraphVisualizations/notableEventsGraph'

const ACTION_GRAPH = 'actionGraph' as const
const CONDENSED_ACTION_GRAPH = 'condensedActionGraph' as const
const NOTABLE_EVENTS_GRAPH = 'notableEventsGraph' as const

type GraphTypes = typeof CONDENSED_ACTION_GRAPH | typeof ACTION_GRAPH | typeof NOTABLE_EVENTS_GRAPH
const graphDetailsMap: Record<
	GraphTypes,
	{
		graphType: GraphTypes
		name: string
		component: (props: {
			graphs: {
				condensedActionGraph: CondensedActionGraph<string>
				actionGraph: ActionGraph<string>
			}
			actions: Array<Action<string>>
		}) => JSX.Element
	}
> = {
	[ACTION_GRAPH]: {
		graphType: ACTION_GRAPH,
		name: 'Action Graph',
		component: (props) => (
			<ActionGraphRenderer actions={props.actions} actionGraph={props.graphs.actionGraph} />
		),
	},
	[CONDENSED_ACTION_GRAPH]: {
		graphType: CONDENSED_ACTION_GRAPH,
		name: 'Condensed Action Graph',
		component: (props) => (
			<CondensedActionGraphRenderer condensedActionGraph={props.graphs.condensedActionGraph} />
		),
	},
	[NOTABLE_EVENTS_GRAPH]: {
		graphType: NOTABLE_EVENTS_GRAPH,
		name: 'Notable Events',
		component: (props) => (
			<NotableEventsGraph condensedActionGraph={props.graphs.condensedActionGraph} />
		),
	},
}

const AVAILABLE_GRAPHS: Array<GraphTypes> = [
	ACTION_GRAPH,
	CONDENSED_ACTION_GRAPH,
	NOTABLE_EVENTS_GRAPH,
]

export function Graphs(): JSX.Element {
	const [currentGraphType, setCurrentGraphType] = useState<GraphTypes>(ACTION_GRAPH)
	const currentGraphDetails = graphDetailsMap[currentGraphType]

	const { data: actions, isLoading, isError } = useEditorActionsWithMediaDuration()
	const initialActionId = useEditorSimulation()?.initialScreenActionId

	const graphs = useMemo(
		() => (actions && initialActionId ? getCondensedActionGraph(initialActionId, actions) : null),
		[actions, initialActionId]
	)

	const deadEndNodes = useMemo(() => {
		if (!graphs) {
			return null
		}

		let count = 0
		graphs.condensedActionGraph.nodes.forEach((node) => {
			if (isCondensedNodeInDeadEnd(node)) {
				count += 1
			}
		})
		return count
	}, [graphs])

	if (isLoading) {
		return (
			<Pad>
				Loading Action Durations... <Spinner />
			</Pad>
		)
	}

	if (!initialActionId) {
		return <Pad>The simulation needs an initialAction to create a graph</Pad>
	}

	if (isError || !actions) {
		return <Pad>An error occurred</Pad>
	}
	if (!graphs) {
		return <Pad>Failed to generate graphs</Pad>
	}
	return (
		<SelectedActionContextProvider>
			<Pad>
				<Header>
					<h2>{currentGraphDetails.name}</h2>
					{graphs.actionGraph.actionsNotInGraph.length ? (
						<div>{graphs.actionGraph.actionsNotInGraph.length} Actions not in graph</div>
					) : null}
					{deadEndNodes ? <div>{deadEndNodes} dead end nodes</div> : null}
					{graphs.actionGraph.actionsReferencedWhichDoNotExist.length ? (
						<div>
							{graphs.actionGraph.actionsReferencedWhichDoNotExist.length} Referenced actions that
							do not exist
						</div>
					) : null}
					<div className="flex flex-row gap-2">
						{AVAILABLE_GRAPHS.map((tab, index) => (
							<Button
								onClick={() => setCurrentGraphType(tab)}
								key={index}
								disabled={tab === currentGraphType}>
								{graphDetailsMap[tab].name}
							</Button>
						))}
					</div>
				</Header>
				<currentGraphDetails.component graphs={graphs} actions={actions} />
			</Pad>
		</SelectedActionContextProvider>
	)
}

const Pad = styled.div`
	padding: 4px;
`

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 4px;
	align-items: center;
`
