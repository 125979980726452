import React, { ReactNode } from 'react'
import FormComponentWrapper, { StyledLabel } from '../../FormComponentWrapper'
import MarkdownInput from '../../../../../common/MarkdownInput'
import { NumberInput } from '../BasicFormComponents'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { Input, Button } from 'reactstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Switch } from '../../../../../common/Switch'
import { LiteracyEventCloseReadingTask } from '@mission.io/mission-toolkit/actions'
import { FaUnderline, FaStrikethrough, FaHighlighter } from 'react-icons/fa'
import { TargetAnnotationCreator } from '@mission.io/question-views'

/**
 * A form component for a user to build a close reading task for a literacy event.
 * @param task The task that is being edited
 * @param onChange A function that will be called with the new task when it is updated
 * @param readingContextText The text that the close reading task needs for student to apply annotations to.
 * @returns A react component that allows the user to edit a close reading task.
 */
export function CloseReadingTask({
	task,
	onChange,
	readingContextText,
}: {
	task: LiteracyEventCloseReadingTask<string>
	onChange: (speakingTask: LiteracyEventCloseReadingTask<string>) => void
	readingContextText: string
}): ReactNode {
	const containerRef = React.useRef(null)
	return (
		<>
			<FormComponentWrapper label="Prompt">
				<MarkdownInput value={task.prompt} onChange={(prompt) => onChange({ ...task, prompt })} />
			</FormComponentWrapper>
			<div className="flex gap-2">
				{!task.annotationLimit ? (
					<Switch
						on={false}
						onChange={() => onChange({ ...task, annotationLimit: 1 })}
						label="Include an annotation limit?"
					/>
				) : (
					<FormComponentWrapper label="Annotation Limit" className="flex flex-col justify-between">
						<NumberInput
							value={task.annotationLimit || 0}
							onChange={(annotationLimit) => onChange({ ...task, annotationLimit })}
						/>
					</FormComponentWrapper>
				)}
				{!task.requiredAnnotations ? (
					<Switch
						on={false}
						onChange={() => onChange({ ...task, requiredAnnotations: 1 })}
						label="Include total required annotations?"
					/>
				) : (
					<FormComponentWrapper
						label="Total required annotations"
						className="flex flex-col justify-between">
						<NumberInput
							value={task.requiredAnnotations || 0}
							onChange={(requiredAnnotations) => onChange({ ...task, requiredAnnotations })}
						/>
					</FormComponentWrapper>
				)}

				<Switch
					on={task.grading.type === LITERACY_EVENT.TASK.CLOSE_READING.GRADING.PASS_FAIL}
					label={
						task.grading.type === LITERACY_EVENT.TASK.CLOSE_READING.GRADING.PASS_FAIL
							? 'Make non-graded'
							: 'Make graded'
					}
					onChange={(value) => {
						onChange({
							...task,
							grading: value
								? { type: LITERACY_EVENT.TASK.CLOSE_READING.GRADING.PASS_FAIL, requiredScore: 1 }
								: { type: LITERACY_EVENT.TASK.CLOSE_READING.GRADING.NONE },
						})
					}}
				/>
				{task.grading.type === LITERACY_EVENT.TASK.CLOSE_READING.GRADING.PASS_FAIL && (
					<FormComponentWrapper label="Required Score" className="flex flex-col justify-between">
						<NumberInput
							value={task.grading.requiredScore}
							onChange={(requiredScore) =>
								onChange({
									...task,
									grading: {
										type: LITERACY_EVENT.TASK.CLOSE_READING.GRADING.PASS_FAIL,
										requiredScore,
									},
								})
							}
						/>
					</FormComponentWrapper>
				)}
			</div>
			<hr className="my-2" />
			<div className="space-y-1">
				<StyledLabel>Available Tools</StyledLabel>
				<div className="flex gap-2 items-center">
					<AnnotationToolCheckBox
						task={task}
						onChange={onChange}
						tool={LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL.HIGHLIGHT}
					/>
					<FaHighlighter /> Highlighter:
					<AnnotationColorPicker
						tool={LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL.HIGHLIGHT}
						task={task}
						onChange={onChange}
					/>
				</div>
				<div className="flex gap-2 items-center">
					<AnnotationToolCheckBox
						task={task}
						onChange={onChange}
						tool={LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL.UNDERLINE}
					/>
					<FaUnderline /> Underline:
					<AnnotationColorPicker
						tool={LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL.UNDERLINE}
						task={task}
						onChange={onChange}
					/>
				</div>
				<div className="flex gap-2 items-center">
					<AnnotationToolCheckBox
						task={task}
						onChange={onChange}
						tool={LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL.STRIKE_THROUGH}
					/>
					<FaStrikethrough /> Strikethrough:
					<AnnotationColorPicker
						tool={LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL.STRIKE_THROUGH}
						task={task}
						onChange={onChange}
					/>
				</div>
			</div>
			<hr className="my-2" />
			<div className="bg-white p-1" ref={containerRef}>
				<TargetAnnotationCreator
					parentRef={containerRef}
					markdown={readingContextText}
					targetAnnotations={task.targetAnnotations}
					setTargetAnnotations={(targetAnnotations) => {
						onChange({ ...task, targetAnnotations })
					}}
					onWarn={toast.warn}
				/>
			</div>
		</>
	)
}

type ColorType = keyof typeof LITERACY_EVENT.TASK.CLOSE_READING.COLOR
const ALL_COLORS: ColorType[] = Object.keys(LITERACY_EVENT.TASK.CLOSE_READING.COLOR) as ColorType[]
// All highlight colors are taken from mission.io/styles game theme, this object and the same colors are also found in the question-views project.
const COLOR_MAP: Record<ColorType, string> = {
	[LITERACY_EVENT.TASK.CLOSE_READING.COLOR.BLUE]: '#95effd', // use accent-blue from mission.io/styles game theme.
	[LITERACY_EVENT.TASK.CLOSE_READING.COLOR.GREEN]: '#39fdc1', // use accent-green from mission.io/styles game theme.
	[LITERACY_EVENT.TASK.CLOSE_READING.COLOR.ORANGE]: '#f7931e', // use accent-orange from mission.io/styles game theme.
	[LITERACY_EVENT.TASK.CLOSE_READING.COLOR.YELLOW]: '#f6d365', // use a lightened prose.gold from mission.io/styles game theme.
	[LITERACY_EVENT.TASK.CLOSE_READING.COLOR.PURPLE]: '#d4d7f7', // use prose.lavender from mission.io/styles game theme.
	[LITERACY_EVENT.TASK.CLOSE_READING.COLOR.PINK]: '#ffd6cc', // use prose.coral from mission.io/styles game theme.
	[LITERACY_EVENT.TASK.CLOSE_READING.COLOR.BLACK]: '#000000', // mission.io/styles black
}

/**
 * The component used to select an array of colors.
 * Adds and updates the availableAnnotationTools array on the close reading task.
 * @param tool The annotation tool type
 * @param task The close reading task
 * @param onChange The function to call when the task is updated
 */
const AnnotationColorPicker = ({
	tool,
	task,
	onChange,
}: {
	tool: keyof typeof LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL
	task: LiteracyEventCloseReadingTask<string>
	onChange: (task: LiteracyEventCloseReadingTask<string>) => void
}) => {
	const toolIndex = task.availableAnnotationTools.findIndex((_tool) => _tool.type === tool)
	const colors = task.availableAnnotationTools[toolIndex]?.colors ?? []
	const onChangeAnnotationToolColors = (newColors: ColorType[]) => {
		onChange({
			...task,
			availableAnnotationTools: [
				...task.availableAnnotationTools.slice(0, toolIndex),
				{
					...task.availableAnnotationTools[toolIndex],
					colors: newColors,
				},
				...task.availableAnnotationTools.slice(toolIndex + 1),
			],
		})
	}

	const colorOptions = ALL_COLORS.filter((color) => !colors.includes(color)).map((color) => ({
		value: color,
		label: color,
		color: COLOR_MAP[color],
	}))

	return (
		<>
			<Select
				placeholder="Select colors"
				isDisabled={toolIndex === -1}
				value={colors.map((color) => ({ value: color, label: color, color: COLOR_MAP[color] }))}
				options={colorOptions}
				isMulti
				onChange={(options) => {
					onChangeAnnotationToolColors(options.map((option) => option.value))
				}}
				closeMenuOnSelect={false}
				formatOptionLabel={(e) => (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<span
							style={{
								height: 10,
								width: 10,
								backgroundColor: e.color,
								borderRadius: '50%',
								marginRight: 10,
							}}></span>
						{e.label}
					</div>
				)}
			/>
			{/* Toggle all selected */}
			<Button
				disabled={toolIndex === -1}
				size="sm"
				onClick={() => {
					if (colors.length === ALL_COLORS.length) {
						onChangeAnnotationToolColors([])
					} else {
						onChangeAnnotationToolColors(ALL_COLORS)
					}
				}}>
				{colors.length === ALL_COLORS.length ? 'Clear All' : 'Select All'}
			</Button>
		</>
	)
}

/**
 * The component used to select an annotation tool.
 * Adds and updates the availableAnnotationTools array on the close reading task.
 * @param tool The annotation tool type
 * @param task The close reading task
 * @param onChange The function to call when the task is updated
 */
const AnnotationToolCheckBox = ({
	tool,
	task,
	onChange,
}: {
	tool: keyof typeof LITERACY_EVENT.TASK.CLOSE_READING.ANNOTATION_TOOL
	onChange: (task: LiteracyEventCloseReadingTask<string>) => void
	task: LiteracyEventCloseReadingTask<string>
}) => {
	const isChecked = task.availableAnnotationTools.some((_tool) => _tool.type === tool)
	return (
		<Input
			type="checkbox"
			className="flex-shrink-0"
			defaultChecked={isChecked}
			onChange={(event) => {
				if (event.target.checked) {
					onChange({
						...task,
						availableAnnotationTools: [
							...task.availableAnnotationTools,
							{ type: tool, colors: [] },
						],
					})
				} else {
					onChange({
						...task,
						availableAnnotationTools: task.availableAnnotationTools.filter(
							(_tool) => _tool.type !== tool
						),
					})
				}
			}}
		/>
	)
}
