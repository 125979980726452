import React from 'react'
import { useEditorSimulation } from '../../../reducers/simulationEditor'
import { ClearAssetDurationCacheForSimulation } from '../Forms/ClearCache'
import { Spinner } from '../../../common/Spinner'

/**
 * CacheTools - a react component that allows users to modify caches
 *
 * @return {JSX.Element}
 */
export function CacheTools(): JSX.Element {
	const simulationId = useEditorSimulation()?._id

	return (
		<div>
			<div className="m-2">
				<p className="mb-2">
					These caches are updated automatically when new files are uploaded. If that fails, they
					can be manually cleared here.
				</p>
				<h1 className="text-lg font-bold">Clear Media Duration Cache</h1>
				<div className="flex flex-row gap-4">
					<div>
						Use this if timings are off during the Mission (eg. videos get cutoff or run too long).
						This clears the media duration cache, which will be repopulated with the actual
						durations the next time the Simulation is started. You may also need to use this if you
						update an asset directly in AWS S3. Pressing this button will cause a long load time for
						this simulation the next time it is loaded.
					</div>
					<div>
						{simulationId ? (
							<ClearAssetDurationCacheForSimulation simulationId={simulationId} />
						) : (
							<Spinner />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
