import {
	CondensedActionGraphNode,
	populateMediaDurationOnActions,
} from '@mission.io/mission-toolkit'
import type { Action } from '@mission.io/mission-toolkit/actions'
import { cloneDeep } from 'lodash'
import { UseQueryResult, useQuery } from 'react-query'
import { useEditorSimulation } from '../../../../../reducers/simulationEditor'
import { useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { styled } from 'styled-components'
import { Graph, GraphConfiguration, GraphData, GraphLink, GraphNode } from 'react-d3-graph'
import { useDimensions } from '../../../../../helpers/hooks'
import React from 'react'
import { ONE_HOUR, ONE_MINUTE, ONE_SECOND } from '../../../../../helpers/constants'

const NODE_SIZE = 150
export const DEFAULT_LINK_COLOR = '#d3d3d3'

export const SUCCESS_COLOR = '#D6B4FA' // light purple
export const FAILURE_COLOR = '#FF7F7F' // light red
export const END_PATH_BREAK_COLOR = 'orange'
export const SELECTED_NODE_COLOR = 'pink'
export const DEAD_END_NODE_COLOR = '#ddd' // light grey

export const DEFAULT_GRAPH_CONFIG = {
	automaticRearrangeAfterDropNode: false,
	collapsible: false,
	directed: true,
	focusAnimationDuration: 0.75,
	focusZoom: 1,
	freezeAllDragEvents: false,
	height: 400,
	highlightDegree: 1,
	highlightOpacity: 1,
	linkHighlightBehavior: false,
	maxZoom: 25,
	minZoom: 0.1,
	initialZoom: 1,
	nodeHighlightBehavior: false,
	panAndZoom: false,
	staticGraph: false,
	staticGraphWithDragAndDrop: false,
	width: 800,
	d3: {
		alphaTarget: 0.05,
		gravity: -100,
		linkLength: 100,
		linkStrength: 1,
		disableLinkForce: false,
	},
	node: {
		color: '#d3d3d3',
		fontColor: 'black',
		fontSize: 8,
		fontWeight: 'normal',
		highlightColor: 'SAME',
		highlightFontSize: 8,
		highlightFontWeight: 'normal',
		highlightStrokeColor: 'SAME',
		highlightStrokeWidth: 'SAME' as const,
		labelProperty: 'id' as const,
		mouseCursor: 'pointer',
		opacity: 1,
		renderLabel: false,
		size: NODE_SIZE,
		strokeColor: 'none',
		strokeWidth: 1.5,
		svg: '',
		symbolType: 'square',
	},
	link: {
		color: DEFAULT_LINK_COLOR,
		fontColor: 'black',
		fontSize: 8,
		fontWeight: 'normal',
		highlightColor: 'SAME',
		highlightFontSize: 8,
		highlightFontWeight: 'normal',
		mouseCursor: 'pointer',
		opacity: 1,
		renderLabel: false,
		semanticStrokeWidth: false,
		strokeWidth: 1.5,
		markerHeight: 6,
		markerWidth: 6,
		strokeDasharray: 0,
		strokeDashoffset: 0,
		strokeLinecap: 'round',
	},
}

/**
 * interpolateBetweenGreenAndLightBlue - get a color between green and light blue
 *
 * @param {number} proportion - how close to light blue the color should be (0 = green, 1 = lightblue)
 *
 * @return {string} - a color
 */
export function interpolateBetweenGreenAndLightBlue(proportion: number): string {
	// Based off of https://stackoverflow.com/questions/7128675/from-green-to-red-color-depend-on-percentage
	return `hsl(${(proportion * 60 + 120).toString(10)}, 100%, 50%)`
}

const DEFAULT_MEDIA_DURATION = 10 * ONE_SECOND

/**
 * getMediaDuration - get the duration for the media associated with the passed url
 *
 * @param {string} url - the url of the media
 * @param {'video'|'audio'} mediaTagName - the html tag to get the media duration from
 *
 * @return {Promise<number>} - the duration (milliseconds) of the media
 */
async function getMediaDuration(url: string, mediaTagName: 'video' | 'audio'): Promise<number> {
	const mediaElement = document.createElement(mediaTagName)
	mediaElement.setAttribute('preload', 'metadata')
	mediaElement.src = url

	const durationPromise: Promise<number> = new Promise((res) => {
		let alreadyResolved = false

		function cleanup(duration: number) {
			if (alreadyResolved) {
				return
			}
			alreadyResolved = true
			res(duration)

			mediaElement.removeEventListener('loadeddata', resolveDuration)
			mediaElement.removeEventListener('loadedmetadata', resolveDuration)
			mediaElement.removeEventListener('error', handleError)
		}

		function resolveDuration() {
			cleanup(mediaElement.duration * ONE_SECOND)
		}

		function handleError(e: unknown) {
			console.error(e)
			cleanup(DEFAULT_MEDIA_DURATION)
		}

		mediaElement.addEventListener('loadeddata', resolveDuration)
		mediaElement.addEventListener('loadedmetadata', resolveDuration)
		mediaElement.addEventListener('error', handleError)
	})

	return durationPromise
}

const MEDIA_DURATION_CALCULATION_KEY = ['actions-with-media-duration']
/**
 * useEditorActionsWithMediaDuration - get the current editor actions with the media duration added to them.
 * Note: returns a copy of the actions with the media duration added.
 *
 * @return {UseQueryResult<Array<Action>>} - a react query results which resolves to the actions with media duration on them.
 */
export function useEditorActionsWithMediaDuration(): UseQueryResult<Array<Action<string>>> {
	const actionMap = useEditorSimulation()?.actions
	const { actions, queryKey } = useMemo(
		() => ({
			actions: Object.values(actionMap ?? {}),
			queryKey: [...MEDIA_DURATION_CALCULATION_KEY, uuid()],
		}),
		[actionMap]
	)

	return useQuery(
		queryKey,
		() =>
			populateMediaDurationOnActions(cloneDeep(actions), {
				getVideoDuration: (url: string) => getMediaDuration(url, 'video'),
				getAudioDuration: (url: string) => getMediaDuration(url, 'audio'),
			}),
		{
			cacheTime: 0, // do not cache, as we do not have a good way of telling which requests are the same
			staleTime: 0, // remove from memory as soon as the query key changes or the hook unmounts to stop a memory leak
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retryOnMount: false,
		}
	)
}

export const StyledNode = styled.div`
	max-width: 100%;
	min-width: 100%;
	max-height: 100%;
	min-height: 100%;
	font-size: 1px;
	padding: 1px;
	border-radius: 1px;

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	& .fill-node {
		max-width: 100%;
		min-width: 100%;
		max-height: 100%;
		min-height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
	}
`

export const StyledGraph = styled(Graph)`
	font-size: 8px;
`

export const GraphWrapper = styled.div`
	width: 100%;
	height: 80vh;
	border: 2px solid #00000070;
	border-radius: 4px;
`

/**
 * SizedGraph - a graph sized to fit the parent container (ie. width 100%, height 100%)
 *
 * @param {ReactD3GraphProps} props - the props for the react-d3-graph Graph component
 *
 * @returns JSX.Element
 */
export function SizedGraph<N extends GraphNode, L extends GraphLink>({
	id,
	data,
	config,
	onClickNode,
}: {
	id: string
	data: GraphData<N, L>
	config: GraphConfiguration<N, L>
	onClickNode?: (nodeId: string, node: N) => unknown
}): JSX.Element {
	const [graphContainer, { width, height }] = useDimensions<HTMLDivElement>()

	const configWithDimensions = useMemo(() => {
		if (width == null || height == null) {
			return config
		}
		return {
			...config,
			width,
			height,
		}
	}, [config, width, height])

	return (
		<StyledGraphWrapper ref={graphContainer}>
			{width != null && height != null ? (
				<StyledGraph
					id={id}
					data={data}
					config={configWithDimensions}
					// @ts-expect-error onClickNode is incorrectly typed in the official types for @react-d3-graph
					onClickNode={onClickNode}
				/>
			) : null}
		</StyledGraphWrapper>
	)
}

const StyledGraphWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
`

/**
 * formatDuration - format the duration (in milliseconds) into a string
 *
 * @param {number} duration - the duration (in milliseconds) to format
 *
 * @return {string}
 */
export function formatDuration(duration: number): string {
	let remainingDuration = duration

	const remainingMs = remainingDuration % ONE_SECOND
	remainingDuration -= remainingMs
	const milliseconds = Math.ceil(remainingMs)

	const remainingSeconds = remainingDuration % ONE_MINUTE
	remainingDuration -= remainingSeconds
	const seconds = remainingSeconds / ONE_SECOND

	const remainingMinutes = remainingDuration % ONE_HOUR
	remainingDuration -= remainingMinutes
	const minutes = remainingMinutes / ONE_MINUTE

	let resultingString = ''
	let populateRest = false
	if (remainingDuration > 0) {
		populateRest = true
		resultingString += `${remainingDuration / ONE_HOUR}h `
	}

	resultingString += `${!populateRest ? minutes : String(minutes).padStart(2, '0')}:${String(
		seconds
	).padStart(2, '0')}.${milliseconds === 0 ? 0 : String(milliseconds).padStart(3, '0')}`
	return resultingString
}

/**
 * isCondensedNodeInDeadEnd - check if a condensed node has a path to the end of the mission or a ship death
 *
 * @param {CondensedActionGraphNode<IdType>} node - the condensed node to check
 *
 * @return {boolean} - true if the node has no path to the end or a ship death, false otherwise.
 */
export function isCondensedNodeInDeadEnd<IdType>(node: CondensedActionGraphNode<IdType>): boolean {
	return (
		node.meta.pessimisticDurationUntilDeath == null && node.meta.pessimisticDurationUntilEnd == null
	)
}
