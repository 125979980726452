import React, { ReactNode } from 'react'
import { Input, FormGroup, Label } from 'reactstrap'

/**
 * Display a reactstrap switch input with a label
 *
 * @param {boolean} on whether the switch is on or off
 * @param {ReactNode} label the label to display next to the switch
 * @param {function} onChange the function to call when the switch is toggled
 * @returns
 */
export function Switch({
	label,
	on,
	onChange,
	...props
}: {
	on: boolean
	label: ReactNode
	onChange: (value: boolean) => void
	disabled?: boolean
	className?: string
	style?: React.CSSProperties
}): ReactNode {
	return (
		<FormGroup switch>
			<Input
				type="switch"
				role="switch"
				checked={on}
				onChange={(event) => {
					onChange(event.target.checked)
				}}
				{...props}
			/>
			<Label check>{label}</Label>
		</FormGroup>
	)
}
