import React, { MouseEvent, useMemo, useRef, useState } from 'react'
import { ActionGraph, ActionGraphNode } from '@mission.io/mission-toolkit'
import {
	Action,
	ActionMap,
	ImageScreenAction,
	StartTimerAction,
	VideoScreenAction,
	VocalTrackAction,
} from '@mission.io/mission-toolkit/actions'
import { styled } from 'styled-components'
import { FaPlay, FaPause } from 'react-icons/fa'
import {
	DEFAULT_GRAPH_CONFIG,
	DEFAULT_LINK_COLOR,
	GraphWrapper,
	SELECTED_NODE_COLOR,
	SizedGraph,
	StyledNode,
	interpolateBetweenGreenAndLightBlue,
} from './graphHelpers'
import { getTitle } from '../../../actionDefinitions'
import { useSelectedActions, useSetSelectedActions } from './Context'

/**
 * playClickedVideo - a function which will play/pause a video element when it is clicked
 *
 * @param {MouseEvent<HTMLVideoElement>} event - the html event for when the video is clicked
 */
const playClickedVideo = (event: MouseEvent<HTMLVideoElement>) => {
	const videoElement = event.target as HTMLVideoElement
	if (
		// check if video is playing
		videoElement.currentTime > 0 &&
		!videoElement.ended &&
		!videoElement.paused
	) {
		videoElement.pause()
	} else {
		videoElement
			.play()
			.catch((error) =>
				console.error(`Unable to play video in graph due to the following error:`, error)
			)
	}
}

const ACTION_TYPE_TO_RENDERER = {
	VIDEO_SCREEN: ({ action }: { action: VideoScreenAction<string> }) => (
		<div>
			<video className="fill-node" src={action.url} onClick={playClickedVideo} preload="metadata" />
			{action._mediaDuration ?? 'unknown '}
			ms
		</div>
	),
	IMAGE_SCREEN: ({ action }: { action: ImageScreenAction<string> }) => (
		<img className="fill-node" src={action.url} alt={action.url} />
	),
	VOCAL_TRACK: function VocalTrackComponent({ action }: { action: VocalTrackAction<string> }) {
		const ref = useRef<HTMLAudioElement | null>(null)
		const [isPlaying, setIsPlaying] = useState(false)
		return (
			<VocalTrackActionDataWrapper
				style={{ fontSize: '2em' }}
				onClick={() => {
					const audioElement = ref.current
					if (!audioElement) {
						return
					}

					if (!isPlaying) {
						audioElement.currentTime = 0
						audioElement.play()
						setIsPlaying(true)
						return
					}

					audioElement.pause()
					setIsPlaying(false)
					return
				}}>
				<div>
					{action._mediaDuration ?? 'unknown '}
					ms
				</div>
				<div>{isPlaying ? <FaPause /> : <FaPlay />}</div>
				<audio ref={ref} src={action.audioUrl} preload="none" />
			</VocalTrackActionDataWrapper>
		)
	},
	START_TIMER: ({ action }: { action: StartTimerAction<string> }) => (
		<div className="fill-node">{action.duration}ms</div>
	),
}

/**
 * ActionGraphRenderer - render the given actionGraph
 *
 * @param {Array<Action>} actions - a list of all the actions in the actionGraph
 * @param {ActionGraph<string>} actionGraph - the action graph to render
 *
 * @return {JSX.Element}
 */
export function ActionGraphRenderer({
	actions,
	actionGraph,
}: {
	actions: Array<Action<string>>
	actionGraph: ActionGraph<string>
}): JSX.Element {
	const actionMap = useMemo(() => {
		const actionMap: ActionMap<string> = {}
		actions.forEach((action) => (actionMap[action._id] = action))
		return actionMap
	}, [actions])

	const { graphConfig } = useMemo(() => {
		const maxDepth = actionGraph.maxDepthFromStart || 1

		const ActionViewGenerator = ({
			node: { id, depthFromStart },
		}: {
			node: ActionGraphNode<string>
		}): JSX.Element => {
			const selectedActions = useSelectedActions()
			const action = actionMap[id]
			const proportionToMaxDepth = depthFromStart / maxDepth
			// @ts-expect-error ACTION_TYPE_TO_RENDERER uses the lookup by the actiontype to check the typing of the actions
			const ActionComponent = action ? ACTION_TYPE_TO_RENDERER[action.type] : null
			return (
				<StyledNode
					style={{
						backgroundColor: selectedActions?.has(id)
							? SELECTED_NODE_COLOR
							: interpolateBetweenGreenAndLightBlue(proportionToMaxDepth),
					}}>
					{action ? getTitle(action) : id}{' '}
					{ActionComponent ? <ActionComponent action={action} /> : null}
					<div>
						<div style={{ fontSize: '0.5px', margin: 0, padding: 0 }}>
							{action ? action.type : 'unknown action'}
						</div>
						<div
							style={{ fontSize: '0.5px', marginTop: '0.5px', marginBottom: '0.5px', padding: 0 }}>
							{id}, {depthFromStart}
						</div>
					</div>
				</StyledNode>
			)
		}
		const graphConfig = {
			...DEFAULT_GRAPH_CONFIG,
			node: {
				...DEFAULT_GRAPH_CONFIG.node,
				viewGenerator: (node: ActionGraphNode<string>) => <ActionViewGenerator node={node} />,
			},
			link: {
				...DEFAULT_GRAPH_CONFIG.link,
				renderLabel: true,
				labelProperty: (link: { linkNames: string[] }) => {
					return link.linkNames.join(', ')
				},
			},
		}

		return { actionGraph, graphConfig }
	}, [actionGraph, actionMap])

	// color links which lead to an END_MISSION action
	const graphWithColoredLinks = useMemo(() => {
		let maxLinkDepthToEnd = 1
		actionGraph.links.forEach((link) => {
			if (link.minStepsTillEnd == null) {
				return
			}
			maxLinkDepthToEnd = Math.max(maxLinkDepthToEnd, link.minStepsTillEnd.stepCount)
		})
		return {
			...actionGraph,
			links: actionGraph.links.map((link) => ({
				...link,
				color:
					link.minStepsTillEnd != null
						? interpolateBetweenGreenAndLightBlue(
								1 - link.minStepsTillEnd.stepCount / maxLinkDepthToEnd
						  )
						: DEFAULT_LINK_COLOR,
			})),
		}
	}, [actionGraph])

	const selectedActions = useSelectedActions()
	const graphToRender = useMemo(() => {
		if (!selectedActions?.size) {
			return graphWithColoredLinks
		}
		return {
			...graphWithColoredLinks,
			focusedNodeId: selectedActions.values().next().value, // updating this causes the graph to try to center the node with the given id
		}
	}, [graphWithColoredLinks, selectedActions])

	const setSelectedActions = useSetSelectedActions()

	return (
		<>
			<label>
				Focus on Action:{' '}
				<input
					type="text"
					placeholder="Action Id"
					onChange={(e) => {
						const actionId = e.target.value
						if (!actionMap.hasOwnProperty(actionId)) {
							setSelectedActions(null)
							return
						}
						setSelectedActions(new Set([actionId]))
					}}
					className="border-solid border border-x-gray-a rounded m-2 p-1"
				/>
			</label>
			<GraphWrapper>
				<SizedGraph
					id="action-graph"
					data={graphToRender}
					config={graphConfig}
					onClickNode={
						// We log the node on click so we can see all fields on the node
						console.log
					}
				/>
			</GraphWrapper>
		</>
	)
}

const VocalTrackActionDataWrapper = styled.div.attrs({ className: 'fill-node' })`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1px;
`
