import axios from 'axios'
import React from 'react'
import { useMutation } from 'react-query'
import { Button } from 'reactstrap'
import { config } from '../../../config'
import { toast } from 'react-toastify'

/**
 * ClearAssetDurationCache - renders a button which can be clicked to remove all entries from the asset duration cache
 *
 * @returns {JSX.Element} a button
 */
export function ClearAssetDurationCache(): JSX.Element {
	// @ts-expect-error this form of useMutation works
	const clearDurationCacheMutation = useMutation(
		async () => axios.delete(`${config.simulationsApiUrl}/api/caches/asset-duration-cache/clear`),
		{
			onSuccess: () => toast.success('Successfully cleared media duration cache.'),
			onError: (err) => {
				console.error(err)
				toast.error('An error occurred when clearing media duration cache.')
			},
		}
	)
	return (
		<Button
			className="whitespace-nowrap"
			disabled={clearDurationCacheMutation.isLoading}
			onClick={() => {
				clearDurationCacheMutation.mutate()
			}}
			color="danger">
			Clear Media Duration Cache
		</Button>
	)
}

/**
 * ClearAssetDurationCacheForSimulation - renders a button which can be clicked to remove all assets of the simulations from the duration cache
 *
 * @returns {JSX.Element} a button
 */
export function ClearAssetDurationCacheForSimulation({
	simulationId,
}: {
	simulationId: string
}): JSX.Element {
	// @ts-expect-error this form of useMutation works
	const clearDurationCacheMutation = useMutation(
		async () =>
			axios.delete(
				`${config.simulationsApiUrl}/api/caches/asset-duration-cache/clear?simulationId=${simulationId}`
			),
		{
			onSuccess: () => toast.success(`Successfully cleared media duration cache for simulation.`),
			onError: (err) => {
				console.error(
					`The following error occurred when clearing media duration cache for ${simulationId}`,
					err
				)
				toast.error(
					`An error occurred when clearing media duration cache for simulation "${simulationId}".`
				)
			},
		}
	)

	return (
		<Button
			className="text-nowrap"
			disabled={clearDurationCacheMutation.isLoading}
			onClick={() => {
				clearDurationCacheMutation.mutate()
			}}
			color="danger">
			Clear Media Duration Cache
		</Button>
	)
}
